import React from "react";
import Trump from "./mod/Trump";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>you are gay</h1>
      <Trump></Trump>
    </div>
  );
}

export default App;
